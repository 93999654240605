import { mapToRecommendations } from './utils';
import { version } from './version';
export function getRelatedProducts({
  objectIDs,
  recommendClient,
  transformItems = x => x,
  fallbackParameters,
  indexName,
  maxRecommendations,
  queryParameters,
  threshold
}) {
  const queries = objectIDs.map(objectID => ({
    fallbackParameters,
    indexName,
    maxRecommendations,
    objectID,
    queryParameters,
    threshold
  }));
  recommendClient.addAlgoliaAgent('recommend-core', version);
  if (queries.length === 0) {
    return Promise.resolve({
      recommendations: []
    });
  }
  return recommendClient.getRelatedProducts(queries).then(response => mapToRecommendations({
    maxRecommendations,
    hits: response.results.map(result => result.hits),
    nrOfObjs: objectIDs.length
  })).then(hits => ({
    recommendations: transformItems(hits)
  }));
}