import { getAverageIndices } from './computeAverageIndices';

/**
 * Returns the reordered list of recommendations based on average indices.
 *
 * @param hits - recommendations
 * @param maxRecommendations - max number of recommendations
 * @param nrOfObjs - total number of products for which we are retrieving recommendations (objectsIDs.length)
 */

export function mapToRecommendations({
  hits,
  maxRecommendations,
  nrOfObjs
}) {
  const indexTracker = {};
  hits.forEach(hitsArray => {
    hitsArray.forEach((hit, index) => {
      if (!indexTracker[hit.objectID]) {
        indexTracker[hit.objectID] = {
          indexSum: index,
          nr: 1
        };
      } else {
        indexTracker[hit.objectID] = {
          indexSum: indexTracker[hit.objectID].indexSum + index,
          nr: indexTracker[hit.objectID].nr + 1
        };
      }
    });
  });
  const sortedAverageIndices = getAverageIndices(indexTracker, nrOfObjs);
  const finalOrder = sortedAverageIndices.reduce((orderedHits, avgIndexRef) => {
    const result = hits.flat().find(hit => hit.objectID === avgIndexRef.objectID);
    return result ? orderedHits.concat(result) : orderedHits;
  }, []);
  return finalOrder.slice(0,
  // We cap the number of recommendations because the previously
  // computed `hitsPerPage` was an approximation due to `Math.ceil`.
  maxRecommendations && maxRecommendations > 0 ? maxRecommendations : undefined);
}