/** @jsxRuntime classic */
/** @jsx createElement */

import { cx } from './utils';
export function createDefaultChildrenComponent({
  createElement,
  Fragment
}) {
  return function DefaultChildren(props) {
    if (props.recommendations.length === 0 && props.status === 'idle') {
      return createElement(props.Fallback, null);
    }
    return createElement("section", {
      className: cx('auc-Recommend', props.classNames.root)
    }, createElement(props.Header, {
      classNames: props.classNames,
      recommendations: props.recommendations,
      translations: props.translations,
      createElement: createElement,
      Fragment: Fragment
    }), createElement(props.View, null));
  };
}