import { mapByScoreToRecommendations, uniqBy } from './utils';
import { version } from './version';
export function getTrendingItems({
  recommendClient,
  transformItems = x => x,
  fallbackParameters,
  indexName,
  maxRecommendations,
  queryParameters,
  threshold,
  facetName,
  facetValue
}) {
  const query = {
    fallbackParameters,
    indexName,
    maxRecommendations,
    queryParameters,
    threshold,
    facetName,
    facetValue
  };
  recommendClient.addAlgoliaAgent('recommend-core', version);
  if (!indexName) {
    return Promise.resolve({
      recommendations: []
    });
  }
  return recommendClient.getTrendingItems([query]).then(response => mapByScoreToRecommendations({
    maxRecommendations,
    // Multiple identical recommended `objectID`s can be returned b
    // the engine, so we need to remove duplicates.
    hits: uniqBy('objectID', response.results.map(result => result.hits).flat())
  })).then(hits => ({
    recommendations: transformItems(hits)
  }));
}